import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';

export const auth =()=>{return firebase.auth();}

export const fsdb = ()=>{
    return firebase.firestore();
}

export const toList = (snapshot) =>{
    const list = [];
    if(snapshot.length == 0){
           return list;
    }

    snapshot.forEach(doc => {
        let model = doc.data(); model.id = doc.id;
        list.push(model);
    });
    return list;
}

export const getModel = async (tbl, id) =>{
    let model = {};
    const db    = fsdb();
    const ref   = await db.collection(tbl).doc(id).get();
    if(ref.exists){              
           model = ref.data();
           model.id = ref.id;
    }
    return model;
}

export const updateFields = async (tbl, id, data) =>{
    const db    = fsdb();
    await db.collection(tbl).doc(id).update(data);
    return id;
}

export const updateFs = async (tbl, id, data) =>{
    const db    = fsdb();
    await db.collection(tbl).doc(id).update(data);
    return id;
}

export const add2Fs = async (tbl, data) =>{
    const db    = fsdb();
    const ref   = await db.collection(tbl).add(data);
    return ref.id;
}

export const delFs = async (tbl, id) =>{
    const db    = fsdb();
    const ref   = await db.collection(tbl).doc(id).delete();
    return id;
}

export const fsCollection = {
    COUREER_IO_MASTER : "COUREER_IO_MASTER",
    COUREER_STATUS : "COUREER_STATUS",
    COUREER_USERS  : "COUREER_USERS"
}

export const localKeys = {
    CYBERXPRESS_USERS : "cyberexpress.in.users",
}