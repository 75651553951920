import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const SignOut = () =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const signOut = () =>{
        dispatch({ type: "LOG_OUT" });
        navigate("/");
    }

    return(<>
        <div className="row">
            <div className="col s12 m4 offset-m4">
                <div className="card">
                    <div className="card-content">
                        <div className="row">
                            <div className="col s12">
                                <h5>Sign Out</h5>
                                <p>Do you want to sign out?</p>
                            </div>
                            <div className="col s12">
                                <button className="btn btn-flat orange white-text" onClick={e=>signOut()}>SIGN OUT</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default SignOut;