import React, { useState, useEffect } from 'react';
import {getConsignment, getStatusList} from "../db/fs.coureer";
import {getStrDate} from "../utils/dates";
import M from "materialize-css/dist/js/materialize.min.js";

const Home = () =>{
    const [cnNo, setCnNo] = useState("");    
    const [cons, setCons] = useState({});
    const [status, setStatus] = useState([]);
    const [detail, setDetail] = useState(0);
    
    const loadConsignment = async () =>{
          let consignment = await getConsignment(cnNo);
          let cStatus     = await getStatusList(cnNo);

          if(Object.keys(consignment).length > 0){
              setCons(consignment);
              setStatus(cStatus);

              setDetail(2);
          }else{
            setCons({});
            setStatus([]);
            setDetail(1);
          }
    } 

    useEffect(()=>{
      M.AutoInit();
      var elems = document.querySelectorAll('.materialboxed');
      var instances = M.Materialbox.init(elems, {});
    }, [])


    return(
        <>
        <div className="row">
          <div className="col s12 m6 l6 offset-m3 offset-l3 center">
            <h4 className="center">Track Your Shipment</h4>
          </div>

          <div className="col s12 m6 l6 offset-m3 offset-l3 center" style={{marginTop: "2em"}}>
            <input className="bordered-input-field center" 
                placeholder="Enter Consignment Number" 
                value={cnNo}
                onChange = {(e)=>{setCnNo(e.target.value)}}
                id="c_number" name="c_number" type="text" />
          </div>

          <div className="col s12 m6 l6 offset-m3 offset-l3 center">
            <button className="btn btn-large green white-text" onClick={()=>loadConsignment()}>Track Shipment</button>
          </div>

          <div className="col s12 m6 l6 offset-m3 offset-l3">
              {detail < 1 ? <Blank /> : detail == 1 ? <NoConsignmentFound /> : <ConsignmentFound consignment = {cons} status = {status}  />}
          </div>
      </div>
      </>
    );
}

const NoConsignmentFound = () =>{
  return(<>
      <div className="row">
          <div className = "col s12" style={{marginTop : "2em"}}>
              <p className="center red-text">Consignment Number Not found</p>
          </div>
      </div>
  </>);
}

const Blank = () =>{return(<></>)}

const ConsignmentFound = ({consignment, status}) =>{

  useEffect(()=>{
    M.AutoInit();
  })

  return(<>
    <div className="row" style={{marginTop : "2em", fontSize : "1.2em"}}>
        <div className = "col s12">
            <div className="card">
                <div className = "card-container">
                     <div className="row">
                        <div className = "col s12 center">
                          <h5 className = "center">Consignment: #{consignment.cnNo}</h5>
                          <p>From {consignment.orgCity} to {consignment.destCity}</p>
                       </div>
                       <div className ="col s12 divider" style={{marginTop : "1em", marginBottom : "2em"}}></div>
                       <div className ="col s12">
                              {status.map(stat => (<StatusDetail key={stat.id} status={stat} />))}
                       </div>
                       <div className ="col s12 center" style={{padding:"2em"}}>
                            <img className='materialboxed' width="100%" src={consignment.podLink}/>
                       </div>
                     </div>
                </div>
            </div>
        </div>     
    </div>
  </>);
}

const StatusDetail = ({status}) =>{
    return(<>
      <div className="row">
        <div className ="col s4 right-align"><strong>{getStrDate(status.longDate)} <br /> {status.cnStatus}</strong></div>
        <div className ="col s8">{status.particulars}</div>
      </div>
    </>);
}


export default Home;