import { useEffect, useState } from "react"
import { getConsignment, getStatusList } from "../db/fs.coureer";
import ConsignDetail from "./ConsignDetail";
import FileUploader from "./FileUploader";

const UploadPOD = () =>{
    const[coureer, setCoureer] = useState({});
    const onReload = () =>{}

    useEffect(()=>{}, [coureer]);
    return(<>
          <div className="row">
            <div className="col s12">
                 <h4>UPLOAD POD</h4>
            </div>
          </div>

          <div className="row">
            <div className="col s12 m6">
                <ConsignmentSrc coureer={coureer} setCoureer={setCoureer} />
                <ConsignDetail coureer={coureer}/>
            </div>
            <div className="col s12 m6">
                <FileUploader coureer = {coureer} key={`fu${Date.now()}`} />
            </div>
          </div>
    </>)
}

const ConsignmentSrc = ({coureer, setCoureer}) =>{
      const[consId, setConsId] = useState("");
      const[kureer, setKureer] = useState(coureer);

      const loadConsignment = async () => {
        let consignment = await getConsignment(consId); console.log(consignment);
        let cStatus     = await getStatusList(consId);
        setKureer         (consignment);
        setCoureer        (consignment)
      }

      const setENTER = async (e) =>{
           if(e.keyCode == 13){
              await loadConsignment();
           }
      }

      return(<>
        <div className="card">
            <div className="card-content">
                <div className="row">
                      <div className="col s12 input-field">
                          <input type="text"
                              onChange={e => {setConsId(e.target.value); setCoureer({}); setKureer({})}}
                              onKeyUp={e=>setENTER(e)}
                              name="consno"
                              id="consno"
                              className="validate"
                              value={consId} />
                          <label htmlFor="consno" className="active">Enter Consignment No</label>
                      </div>
                      <div className="col s12">
                              <button className="btn btn-flat green white-text" onClick={e=>loadConsignment()}>LOAD CONSIGNMENT</button>
                      </div>
                      <div className="col s12">
                          
                      </div>
                </div>
            </div>
        </div>
      </>)
}



export default UploadPOD;