import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "../pages/Home";
import SignIn from '../user/SignIn';
import AdmHome from '../adm/AdmHome';
import { useSelector } from 'react-redux';
import UploadPOD from '../status/UploadPOD';
import SignOut from '../user/SignOut';

const Mains = () => {
    const admin = useSelector(state => state.softuser)
    if(admin.id.length === 0){
        console.log("no login detail found");
        return <PublicView />
    }

    return (<Admin />);
}

const PublicView = () =>{
    return(
        <div className="container">
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/user/sign/in" element={<SignIn />} />
            </Routes>
        </Router>
        </div>
    )
}


const Admin = () => {
    return (<>
        <div className="container">
            <Router>
                <Routes>
                    <Route exact path="/"                   element={<AdmHome />} />
                    <Route exact path="/adm/home"           element={<AdmHome />} />
                    <Route exact path="/status/pod/upload"  element={<UploadPOD />} />
                    
                    <Route exact path="/user/sign/in"       element={<SignIn />} />
                    <Route exact path="/user/sign/out"      element={<SignOut />} />
                </Routes>
            </Router>
        </div>
    </>)
}



export default Mains;
