import { fsCollection, fsdb, toList } from "../fs/fire"


export const getUserByPhone = async (phoneNo) => {
      const db      = fsdb();
      const snap    = await db.collection(fsCollection.COUREER_USERS)
                            .where("phoneNo", "==", phoneNo).limit(1).get();
      const list    = toList(snap);

      if(list.length > 0){
            return list[0];
      }

      return {};
}