import { useEffect, useState } from "react"
import { cropImage, imageUpload } from "../utils/file.upload";
import { updatePodLink } from "../db/fs.coureer.save";
import { signOut } from "firebase/auth";
import { updateDelivered } from "../db/fs.coureer.status";

const FileUploader = ({coureer}) => {
    const[inProg, setInProg] = useState(false);
    const[image, setImage] = useState(null);
    const[imgPreview, setImgPreview] = useState(coureer.podLink);

    const onFileSelect = async (e) =>{
        const sfile = e.target.files[0];
        if(sfile === null) {return;}
        if(sfile === undefined) {return;}

        cropImage(URL.createObjectURL(sfile), 500, 300, (file)=>{
            setImgPreview(URL.createObjectURL(file));
            setImage(file);
            uploadFile(file);
          });
    }

    const uploadFile = async (file) =>{
          setInProg(true);
          console.log(file.name + " " + file.size);
          const link = await imageUpload(file, "cyberexpress/pod/"+coureer.id+".jpg");
          const done = await updatePodLink(coureer.id, link);
          const status = await updateDelivered(coureer.cnNo, link);
          setInProg(false);
    }


    return(Object.keys(coureer).length === 0 ? <></> :
        <>
            <div className="card">
                <div className="card-content">
                    <div className="row">
                        <div className="col s12"><h5>Upload file | {coureer.cnNo}</h5></div>
                        <div className="col s12 file-field input-field center">
                            <div className="btn blue-grey white-text">
                                <span>UPLOAD FILE</span>
                                <input type="file" name="file" id="file" onChange={e => onFileSelect(e)} />
                            </div>
                            <div className="file-path-wrapper">
                                <input className=" file-path validate" placeholder="Upload File" type="text" />
                            </div>
                        </div>
                        <div className="col s12">{inProg &&
                            <div className="progress">
                                <div className="indeterminate"></div>
                            </div>}
                        </div>
                        <div className="col s12">
                           
                        </div>
                        <div className="col s12" style={{marginTop:"3em"}}>
                            <img src={imgPreview} key={coureer.id} width="100%" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FileUploader;