import { useState } from "react";
import { getUserByPhone } from "../db/fs.user";
import { localKeys } from "../fs/fire";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [phoneNo, setPhoneNo]     = useState("");
    const [password, setPassword]   = useState("");
    const [msg, setMsg] = useState({err: false, txt: ""});
    

    const login = async () => {
          const usr = await getUserByPhone(phoneNo);
          if(Object.keys(usr).length === 0) {
                setMsg({err : true, txt: "No user found"});
                return;
          }

          if(usr.password === password){
                setMsg({err : false, txt: "Login success."});
                delete usr.password;
                usr.lastActive = Date.now();

                localStorage.setItem(localKeys.CYBERXPRESS_USERS, JSON.stringify(usr));
                dispatch({ type: "LOG_IN" });
                navigate("/adm/home");
          }else{
            setMsg({err : true, txt: "Incorrect password"});
          }
    }

    
    return (
        <>
            <div className="row">
                <div className="col s12 m4 offset-m4">
                    <div className="card z-depth-5">
                        <div className="card-content">
                            <div className="row">
                                <div className="col s12">
                                    <h2 style={{ fontFamily: "Economica" }}>Sign In</h2>
                                    <p className={msg.err ? "red-text" : "black-text"}>{msg.txt}</p>
                                </div>
                                <div className="input-field col s12">
                                    <input id="phone_no" value={phoneNo} onChange={e => setPhoneNo(e.target.value)} type="number" />
                                    <label for="phone_no" className="active">Phone No</label>
                                </div>
                                <div className="input-field col s12">
                                    <input id="password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
                                    <label for="password" className="active">Password</label>
                                </div>
                                <div className="col s12">
                                    <button className="btn waves-effect" onClick={e=>login()}>Sign In</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default SignIn;