import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const Header = () => {
    const admin = useSelector(state => state.softuser)

    if(admin.id.length == 0){
          return(<HeaderNav />)
    }

    return (
        <AdminNav />
    );
}


const HeaderNav = () => {
    return (<>

        <nav>
            <div className="nav-wrapper teal darken-4">
                <a href="/" className="brand-logo"><i className="material-icons"></i>CyberExpress</a>
                <a href="#" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                <ul className="right">
                    <li className="active"><a href="/">Home</a></li>
                    <li className="active"><a href="/user/sign/in">Sign In</a></li>
                </ul>
                <ul className="sidenav" id="mobile-demo">
                    <li className="active"><a href="/">Home</a></li>
                    <li className="active"><a href="/user/sign/in">Sign In</a></li>
                </ul>
            </div>
        </nav>

    </>);
}


const AdminNav = () => {
    return (<>

        <nav>
            <div className="nav-wrapper teal darken-4">
                <a href="/" className="brand-logo"><i className="material-icons"></i>CYBER EXPRESS</a>
                <a href="#" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                <ul className="right">
                    <li className="active"><a href="/adm/home">Home</a></li>
                    <li className="active"><a href="/user/sign/out">Sign Out</a></li>
                </ul>
                <ul className="sidenav" id="mobile-demo">
                    <li className="active"><a href="/adm/home">Home</a></li>
                    <li className="active"><a href="/user/sign/out">Sign OUT</a></li>
                </ul>
            </div>
        </nav>

    </>);
}



export default Header;
