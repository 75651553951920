import { localKeys } from "../fs/fire";

const softuser = (softuser = {id : ""}, action) => {
    softuser = getSUser();
    switch (action.type) {
        case "LOG_IN": return getSUser();
        case "LOG_OUT" : logout(); return {id : ""};
    }

    return softuser;
}

const logout = () =>{
    localStorage.clear();
}

const getSUser = () =>{
    const _auth = localStorage.getItem(localKeys.CYBERXPRESS_USERS);
    if(_auth != null){
        const usr = JSON.parse(_auth);
        if((Date.now() - usr.lastActive) > 3600000){
            return {id : ""};
        }

        return usr;
    }

    return {id : ""};
}


export default softuser;