import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import M from "materialize-css/dist/js/materialize.min.js";


import firebase from "firebase/compat/app";
import {firebaseConfig} from "./fs/conf";

import Mains from "./global/Mains";
import { useEffect } from "react";
import Header from "./global/Header";

function App() {
  const app = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
  
  useEffect(()=>{
      M.AutoInit();
  }, []);

  
  return (
    <>
      <Header />
      <Mains />
    </>
      
  
  );
}

export default App;
