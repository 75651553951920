const ConsignDetail = ({coureer}) =>{
    if(Object.keys(coureer).length === 0){
        return(<></>)
    }

    return(<>
           <div className="card">
                <div className="card-content">
                     <div className="row">
                        <div className="col s12">
                            <h5>No: #{coureer.cnNo}</h5>
                        </div>
                        <div className="col s3">To: </div>
                        <div className="col s9">{coureer.destCity} [ {coureer.destCityCode} ]</div>

                        <div className="col s3">From </div>
                        <div className="col s9">{coureer.orgCity}</div>
                     </div>
                </div>
           </div>
    </>)
}

export default ConsignDetail;